import React from "react";
import { Link } from "react-router-dom";

const VerificationSent = () => {
  return (
    <div className="min-h-screen bg-[#2B5534] flex items-center justify-center">
      <div className="bg-white rounded-3xl w-full max-w-md mx-4 p-8">
        <div className="text-center">
          <h2 className="text-[#2B5534] text-2xl font-semibold mb-4">Verification Email Sent</h2>
          <p className="text-gray-600 mb-6">
            A verification email has been sent to your email address from info@courselinx.com. Please check your email and follow the instructions to complete your registration.
          </p>
          <Link
            to="/login"
            className="inline-block bg-[#4CAF50] text-white py-2 px-6 rounded-lg hover:bg-[#45a049] transition-colors"
          >
            Go to Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default VerificationSent;