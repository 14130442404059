import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Search, User, Settings, LogOut, Trash2 } from 'lucide-react';
import axios from 'axios';

axios.defaults.withCredentials = true;

export default function SearchStudents({ setIsAuthenticated }) {
  const [className, setClassName] = useState('');
  const [term, setTerm] = useState('');
  const [classType, setClassType] = useState('taken');
  const [nameSearchQuery, setNameSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchType, setSearchType] = useState(null); 
  const [showNoResults, setShowNoResults] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const [currentUser, setCurrentUser] = useState(() => {
    const savedUser = localStorage.getItem('currentUser');
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [currentUniversity, setCurrentUniversity] = useState(() => {
    return localStorage.getItem('currentUniversity') || '';
  });

  const isValidTerm = (term) => {
    if (!term) return true; // term is optional
    const trimmedTerm = term.trim();
    const termRegex = /^[A-Z]{4,}\d{2}$/;
    return termRegex.test(trimmedTerm);
  };

  const prepareClassQuery = (className, term) => {
    return {
      trimmedClassName: className.trim(),
      trimmedTerm: term.trim()
    };
  };
  
  const prepareNameQuery = (nameQuery) => {
    return nameQuery.trim();
  };

 

  // Intersection Observer callback
  const lastResultElementRef = useCallback(node => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoading, hasMore]);

  // Reset pagination when search type or query changes
  useEffect(() => {
    setPage(1);
    setHasMore(true);
  }, [className, term, classType, nameSearchQuery, searchType]);

  // Function to shuffle array (Fisher-Yates algorithm)
  const shuffleArray = (array) => {
    let currentIndex = array.length, randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array;
  };


  useEffect(() => {
    // Only fetch if we don't have the data in localStorage
    if (!currentUser || !currentUniversity) {
      const fetchCurrentUser = async () => {
        try {
          const response = await axios.get('/api/profile/me');
          setCurrentUser(response.data);
          setCurrentUniversity(response.data.university);
          // Save to localStorage for future page loads
          localStorage.setItem('currentUser', JSON.stringify(response.data));
          localStorage.setItem('currentUniversity', response.data.university);
          setError('');
        } catch (error) {
          console.error('Failed to fetch current user data:', error);
          setError('Failed to fetch user data. Please try again later.');
        }
      };
      fetchCurrentUser();
    }
  }, []);

  // Add these useEffects to clear results when inputs change
  useEffect(() => {
    setSearchResults([]);
    setShowNoResults(false);
  }, [classType]); // Clear when status changes

  useEffect(() => {
    setSearchResults([]);
    setShowNoResults(false);
  }, [className]); // Clear when Course ID changes

  useEffect(() => {
    setSearchResults([]);
    setShowNoResults(false);
  }, [term]); // Clear when term changes



// Remove the existing validateTerm function and modify handleClassSearch to use the new validation:
const handleClassSearch = async (e) => {
  e.preventDefault();
  setShowNoResults(false);
  setSearchType('class');
  
  const { trimmedClassName, trimmedTerm } = prepareClassQuery(className, term);
  
  if (!trimmedClassName) {
    setSearchResults([]);
    setError('');
    return;
  }

  // Add term validation
  if (trimmedTerm && !isValidTerm(trimmedTerm)) {
    setError('Please type the full term name in capital letters followed by two numbers, with no spaces (e.g., FALL23, WINTER23)');
    return;
  }
  
  setIsLoading(true);
  try {
    const response = await axios.get(
      `/api/students/${trimmedClassName}/${classType}`,
      { params: { term: trimmedTerm } }
    );
    const shuffledResults = shuffleArray([...response.data]);
    setSearchResults(shuffledResults.slice(0, 20));
    setHasMore(shuffledResults.length > 20);
    fullResultsRef.current = shuffledResults;
    setError('');
    setTimeout(() => {
      setShowNoResults(true);
    }, 500);
  } catch (error) {
    setSearchResults([]);
    if (error.response?.status === 400) {
      setError(error.response.data.detail);
      setShowNoResults(false);
    } else {
      setTimeout(() => {
        setShowNoResults(true);
      }, 500);
    }
  } finally {
    setIsLoading(false);
  }
};

  // Reference to store full results for pagination
  const fullResultsRef = useRef([]);

  // Effect to handle pagination
  useEffect(() => {
    if (page > 1 && fullResultsRef.current.length > 0) {
      const start = (page - 1) * 20;
      const end = start + 20;
      const newResults = fullResultsRef.current.slice(0, end);
      setSearchResults(newResults);
      setHasMore(fullResultsRef.current.length > end);
    }
  }, [page]);

  const handleNameSearch = async (e) => {
    e.preventDefault();
    setShowNoResults(false);
    setSearchType('name_bio');
    
    const trimmedQuery = prepareNameQuery(nameSearchQuery);
    
    setIsLoading(true);
    try {
      const response = await axios.get(
        '/api/search-users',
        { params: { query: trimmedQuery } }
      );
      setSearchResults(response.data.slice(0, 20));
      setHasMore(response.data.length > 20);
      fullResultsRef.current = response.data;
      setError('');
    } catch (error) {
      setSearchResults([]);
    } finally {
      setTimeout(() => {
        setShowNoResults(true);
      }, 500);
      setIsLoading(false);
    }
  };


  const handleLogout = async () => {
    try {
      await axios.post('/api/logout');
      // Clear localStorage
      localStorage.removeItem('currentUser');
      localStorage.removeItem('currentUniversity');
      setIsAuthenticated(false);
      window.location.href = '/login';
    } catch (error) {
      console.error('Logout error:', error);
      setError('Failed to log out. Please try again.');
    }
  };

  useEffect(() => {
    if (!className.trim() && !term.trim()) {
      setSearchResults([]);
      setError('');
    }
  }, [className, term]);
  
  useEffect(() => {
    setSearchResults([]);
    setError('');
    setShowNoResults(false);  // This will now clear on ANY change to nameSearchQuery
  }, [nameSearchQuery]);

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="bg-white border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <h1 className="text-xl font-semibold text-green-800">CourseLinx</h1>
            </div>

            <div className="flex items-center space-x-8">
              <a href="/profile/me" className="text-gray-600 hover:text-gray-900 text-sm flex items-center space-x-2">
                <User className="h-4 w-4" />
                <span>Profile</span>
              </a>
              <button
                onClick={handleLogout}
                className="text-gray-600 hover:text-gray-900 text-sm flex items-center space-x-2"
              >
                <LogOut className="h-4 w-4" />
                <span>Logout</span>
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <main className="max-w-3xl mx-auto px-4 pt-3 pb-16">
        {/* Welcome Section */}
        <div className="bg-white rounded-lg p-6 mb-6">
          <h2 className="text-2xl font-medium text-gray-900 mb-2">
            Welcome back, {currentUser?.name || 'Student'}!
          </h2>
          <p className="text-gray-600">
            Your University: {currentUniversity}
          </p>
        </div>

        {error && (
          <div className="bg-red-100 text-red-800 px-4 py-2 rounded mb-4">{error}</div>
        )}

        {/* Search by Class Section */}
        <div className="bg-white rounded-lg p-6 mb-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Search Students by Course</h3>
          <form onSubmit={handleClassSearch} className="space-y-4">
            <div className="flex flex-col space-y-4">
              <div>
                <label className="block text-sm mb-1">Course ID</label>
                <p className="text-xs text-gray-600 mb-1">Capitalize all letters of the subject as it appears on your transcript, and include the course number with no spaces. Ex: 'ECON 462' is 'ECON462'.</p>
                <input
                  type="text"
                  placeholder="Course ID"
                  value={className}
                  onChange={(e) => setClassName(e.target.value.replace(/\s+/g, ''))}
                  required
                  className="w-full px-4 py-2.5 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-green-500 text-sm"
                />
              </div>
              <div>
                <label className="block text-sm mb-1">Term (Optional)</label>
                <p className="text-xs text-gray-600 mb-1">Capitalize all letters of the term name, and join it with the last two numbers of the year without spaces. Ex: 'Fall 2023' is 'FALL23'.</p>
                <input
                  type="text"
                  placeholder="Term"
                  value={term}
                  onChange={(e) => setTerm(e.target.value.replace(/\s+/g, ''))}
                  className="w-full px-4 py-2.5 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-green-500 text-sm"
                />
              </div>
              <label className="block text-sm mb-0.5">Status</label>
              <div className="flex space-x-4">
                <select
                  value={classType}
                  onChange={(e) => setClassType(e.target.value)}
                  required
                  className="px-4 py-2.5 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-green-500 text-sm text-gray-600"
                >
                  <option value="taken">Taken</option>
                  <option value="current">Current</option>
                  <option value="planned">Planned</option>
                </select>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="px-8 py-2.5 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50 text-sm font-medium transition-colors"
                >
                  {isLoading ? 'Searching...' : 'Search'}
                </button>
              </div>
            </div>
          </form>
        </div>

        {/* Search by Name Section */}
        <div className="bg-white rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Search Students by Name</h3>
          <form onSubmit={handleNameSearch} className="flex space-x-4">
            <input
              type="text"
              placeholder="Name"
              value={nameSearchQuery}
              onChange={(e) => setNameSearchQuery(e.target.value)}
              required
              className="flex-1 px-4 py-2.5 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-green-500 text-sm"
            />
            <button
              type="submit"
              disabled={isLoading}
              className="px-8 py-2.5 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50 text-sm font-medium transition-colors"
            >
              {isLoading ? 'Searching...' : 'Search'}
            </button>
          </form>
        </div>
      
      {searchResults.length > 0 && (
        <div className="mt-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {searchResults.map((result, index) => (
              <div
                key={index}
                ref={index === searchResults.length - 1 ? lastResultElementRef : null}
                className="p-4 border border-gray-200 rounded-lg hover:border-green-200 transition-colors"
              >
                <div className="flex items-center space-x-3">
                  {result.profile_picture ? (
                    <img
                      src={result.profile_picture}
                      alt={result.name}
                      className="h-10 w-10 rounded-full object-cover"
                    />
                  ) : (
                    <div className="h-10 w-10 rounded-full bg-green-50 flex items-center justify-center">
                      <User className="h-5 w-5 text-green-600" />
                    </div>
                  )}
                  <div>
                    <h4 className="font-medium text-gray-900">{result.name}</h4>
                    <p className="text-sm text-gray-500">{result.email}</p>
                  </div>
                </div>
                {searchType === 'class' && (
                  <p className="mt-2 text-sm text-gray-600">Course: {className}</p>
                )}
                {result.term && (
                  <p className="mt-2 text-sm text-gray-600">Term: {result.term}</p>
                )}
                {searchType === 'name_bio' && result.bio && (
                  <p className="mt-2 text-sm text-gray-600">Bio: {result.bio}</p>
                )}
                <a
                  href={`/profile/${result.uid}`}
                  className="mt-3 text-green-600 hover:text-green-700 text-sm font-medium inline-block"
                >
                  View Profile
                </a>
              </div>
            ))}
          </div>
          {isLoading && (
            <div className="text-center mt-4">
              <p className="text-gray-600">Loading more results...</p>
            </div>
          )}
        </div>
      )}

      {/* No Results Messages */}
      {searchType === 'class' && searchResults.length === 0 && className.trim() !== '' && showNoResults && (
        <div className="bg-yellow-50 border border-yellow-200 text-yellow-800 px-4 py-3 rounded-lg mt-4">
          No students found for {className} {term ? `in ${term}` : ''} with {classType} status.
        </div>
      )}
      {searchType === 'name_bio' && searchResults.length === 0 && nameSearchQuery.trim() !== '' && showNoResults && (
        <div className="bg-yellow-50 border border-yellow-200 text-yellow-800 px-4 py-3 rounded-lg mt-4">
          No students found with name "{nameSearchQuery}".
        </div>
      )}
      </main>
     {/* Feedback Banner */}
<div className="w-full bg-white border-t border-gray-200 text-gray-900 py-2 text-center text-sm mt-8">
  To submit feedback, please email info@courselinx.com
</div>
    </div>
  );
}