import React, { useState, useEffect } from 'react';
import { ChevronDown, Users, BookOpen, Calendar, MessageCircle, Compass, Mail, Leaf, Star, User, LogOut, LogIn } from 'lucide-react';

export default function LandingPage() {
  const [isVisible, setIsVisible] = useState({});
  const [activeTab, setActiveTab] = useState('students');

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const updates = {};
        entries.forEach(entry => {
          updates[entry.target.id] = entry.isIntersecting;
        });
        setIsVisible(prev => ({...prev, ...updates}));
      },
      { threshold: 0.1 }
    );
  
    const elements = document.querySelectorAll('[data-animate]');
    elements.forEach(el => observer.observe(el));
  
    return () => {
      elements.forEach(el => observer.unobserve(el));
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    return () => {
      document.querySelectorAll('[data-animate]').forEach(el => {
        el.classList.remove('opacity-0', 'opacity-100');
      });
    };
  }, []);

  const faqs = [
    {
      question: "What is CourseLinx?",
      answer: "CourseLinx is your academic pathfinder, connecting you with fellow students who have already blazed the trail through your courses. Simply find students who have taken the courses you're interested in, and contact them with any questions. Think of it as having a map through the academic forest, drawn by those who've already made the journey."
    },
    {
      question: "How does CourseLinx work?",
      answer: "Like a well-marked trail, CourseLinx helps you navigate your academic journey. Sign up with your university email, mark the courses you've taken, currently taking, and/or plan to take, and connect with experienced students."
    },
    {
      question: "What makes CourseLinx different?",
      answer: "Instead of generic course reviews, CourseLinx provides personal connections with real students who've taken your exact courses. It's like having a personal guide rather than just a map."
    },
    {
      question: "Is CourseLinx free?",
      answer: "Yes! CourseLinx is free for all university students. Simply bring your .edu email address, and you're ready to network and find people who can help."
    }
  ];

  const testimonials = [
    {
      name: "Mark C.",
      role: "Biology Major",
      text: "As a pre-medical student, CourseLinx helped me connect with students from my university who had gone through the pre-requisites and medical school application process. Because of their help, I was able to get admitted to a top medical school."
    },
    {
      name: "David A.",
      role: "Computer Science Major", 
      text: "Thanks to CourseLinx, I found students who had taken the same Computer Science classes that I wanted to take, and got invaluable advice about them. I was able to plan my courses better and am closer to graduating now."
    },
    {
      name: "Lisa R.",
      role: "Economics Major",
      text: "Because of CourseLinx, the insights I got about combining Business Fundamentals with Intro to Economics saved my semester. I wish something like this had existed before!"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-50 to-white">
        {/* Navigation */}
        <nav className="bg-white border-b border-gray-200 sticky top-0 z-50">
          <div className="max-w-7xl mx-auto px-8">
            <div className="flex justify-between h-16">
              <div className="flex items-center">
                <h1 className="text-xl font-semibold text-green-800">CourseLinx</h1>
              </div>
              
              <div className="flex items-center space-x-8">
                
              <button 
          onClick={() => window.location.href = '/login'}
          className="text-gray-600 hover:text-gray-900 text-sm flex items-center space-x-2"
        >
          <LogIn className="h-4 w-4" />
          <span>Login/Signup</span>
        </button>
              </div>
            </div>
          </div>
        </nav>
      {/* Hero Section */}
      <div className="relative overflow-hidden bg-gradient-to-b from-green-900 to-green-800">
      <div className="absolute inset-0 opacity-20">
        <div
          className="absolute inset-0 bg-[url('/public/pattern.svg')] bg-repeat"
        />
      </div>
  <div className="container mx-auto px-4 py-32 relative">
    <div className="text-center max-w-4xl mx-auto">
      <h1 className="text-5xl md:text-6xl font-bold text-white mb-6 animate-fade-in">
        Navigate Your Academic Journey
      </h1>
      <p className="text-xl md:text-2xl text-green-100 mb-8">
        Connect with experienced students who've blazed the trail before you.
      </p>
      <a 
        href="/signup" 
        className="bg-green-500 text-white px-8 py-4 rounded-full text-lg font-semibold hover:bg-green-400 transition-all transform hover:scale-105 shadow-lg"
      >
        Start Your Journey
      </a>
    </div>
  </div>
</div>

      {/* Features Section */}
      <div id="features" data-animate="true" className={`py-24 transition-opacity duration-1000 ${isVisible.features ? 'opacity-100' : 'opacity-0'}`}>
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16 text-green-900">Your Guide to College Classes</h2>
          <div className="grid md:grid-cols-4 gap-12">
            {[
              { icon: Compass, title: "Find Your Way", desc: "Navigate your academic path with confidence" },
              { icon: Users, title: "Connect with Guides", desc: "Learn from those who've gone before" },
              { icon: Leaf, title: "Grow Together", desc: "Build a community of shared knowledge" },
              { icon: Star, title: "Chart Your Course", desc: "Plan your academic journey wisely" }
            ].map((feature, i) => (
              <div key={i} className="text-center p-8 bg-white rounded-xl shadow-lg hover:shadow-xl transition-all transform hover:-translate-y-1">
                <div className="w-20 h-20 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-6">
                  <feature.icon className="w-10 h-10 text-green-600" />
                </div>
                <h3 className="text-2xl font-semibold mb-4 text-green-900">{feature.title}</h3>
                <p className="text-gray-600 text-lg">{feature.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Interactive Demo Section */}
      <div id="demo" data-animate="true" className={`py-24 bg-green-50 transition-opacity duration-1000 ${isVisible.demo ? 'opacity-100' : 'opacity-0'}`}>
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16 text-green-900">How It Works</h2>
          <div className="flex justify-center mb-12">
            <div className="bg-white rounded-full p-2 shadow-md">
              {['students', 'courses', 'connect'].map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`px-8 py-3 rounded-full transition-all text-lg ${
                    activeTab === tab ? 'bg-green-500 text-white' : 'text-green-900 hover:bg-green-100'
                  }`}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-lg p-12">
            {activeTab === 'students' && (
              <div className="animate-fade-in text-center">
                <h3 className="text-3xl font-semibold mb-6 text-green-900">Find Fellow Students</h3>
                <p className="text-xl text-gray-600">Search for students who've taken your courses and learn from their experiences by reaching out to them.</p>
              </div>
            )}
            {activeTab === 'courses' && (
              <div className="animate-fade-in text-center">
                <h3 className="text-3xl font-semibold mb-6 text-green-900">Explore Courses</h3>
                <p className="text-xl text-gray-600">Browse through courses and contact students to see real insights.</p>
              </div>
            )}
            {activeTab === 'connect' && (
              <div className="animate-fade-in text-center">
                <h3 className="text-3xl font-semibold mb-6 text-green-900">Make Connections</h3>
                <p className="text-xl text-gray-600">Connect directly with experienced students and get personalized advice.</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div id="testimonials" data-animate="true" className={`py-24 transition-opacity duration-1000 ${isVisible.testimonials ? 'opacity-100' : 'opacity-0'}`}>
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16 text-green-900">Testimonials</h2>
          <div className="grid md:grid-cols-3 gap-12">
            {testimonials.map((testimonial, i) => (
              <div key={i} className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all">
                <p className="text-xl text-gray-600 mb-6">"{testimonial.text}"</p>
                <div className="font-semibold text-xl text-green-900">{testimonial.name}</div>
                <div className="text-lg text-green-600">{testimonial.role}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div id="faq" data-animate="true" className={`py-24 bg-green-50 transition-opacity duration-1000 ${isVisible.faq ? 'opacity-100' : 'opacity-0'}`}>
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16 text-green-900">Guide/FAQ</h2>
          <div className="max-w-3xl mx-auto space-y-6">
            {faqs.map((faq, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-all p-8">
                <div className="flex justify-between items-center cursor-pointer">
                  <h3 className="text-xl font-semibold text-green-900">{faq.question}</h3>
                  <ChevronDown className="w-6 h-6 text-green-500" />
                </div>
                <p className="mt-6 text-lg text-gray-600">{faq.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-green-900 py-24">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-bold text-white mb-8">
            Ready to Begin Your Journey?
          </h2>
          <p className="text-2xl text-green-100 mb-12">
            Join thousands of students finding their way through the academic forest.
          </p>
           <a href="/signup" 
            className="bg-green-500 text-white px-8 py-4 rounded-full text-lg font-semibold hover:bg-green-400 transition-all transform hover:scale-105 shadow-lg"
            >
            Join CourseLinx Today
            </a>
        </div>
      </div>
      {/* Footer Section */}
      <footer className="bg-green-900 py-4">
        <div className="container mx-auto px-4 text-center">
          <p className="text-sm text-white">
            CourseLinx™, a product of INSILICO Digital LLC.
          </p>
        </div>
      </footer>
    </div>
  );
}