import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import SearchStudents from "./components/SearchStudents";
import LoginPage from "./components/LoginPage";
import PrivateRoute from "./components/PrivateRoute";
import ProfilePage from "./components/ProfilePage";
import MyProfilePage from "./components/MyProfilePage";
import axios from "axios";
import LandingPage from "./components/LandingPage";
import SignupPage from "./components/SignupPage";
import VerificationSent from "./components/VerificationSent";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";

// axios.defaults.baseURL = 'http://localhost:8000';

axios.defaults.withCredentials = true;
const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // null indicates loading

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get("/api/profile/me");
        if (response.status === 200) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    };
    checkAuth(); 
  }, []);

  return (
    <Router>
      <Routes>
        {/* Login Page */}
        <Route
          path="/login"
          element={
            isAuthenticated ? (
              <Navigate to="/students" replace />
            ) : (
              <LoginPage setIsAuthenticated={setIsAuthenticated} />
            )
          }
        />

        <Route path="/profile/me" element={<MyProfilePage setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/profile/:id" element={<ProfilePage setIsAuthenticated={setIsAuthenticated} />} />
        
        <Route
          path="/students"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              {/* <Provider> */}
              <SearchStudents setIsAuthenticated={setIsAuthenticated}/>
              {/* </Provider> */}
            </PrivateRoute>
          }
        />

          <Route
          path="/"
          element={<LandingPage />
          }
        />


        <Route
          path="/signup"
          element={
            isAuthenticated ? (
              <Navigate to="/students" replace />
            ) : (
              <SignupPage setIsAuthenticated={setIsAuthenticated} />
            )
          }
        />

        <Route
          path="/verification-sent"
          element={<VerificationSent/>
          }
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/my-profile" element={<MyProfilePage setIsAuthenticated={setIsAuthenticated} />} />

      </Routes>
    </Router>
  );
};

export default App;