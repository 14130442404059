import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { Eye, EyeOff } from "lucide-react";


const isValidTerm = (term) => {
  const trimmedTerm = term.trim();
  const termRegex = /^[A-Z]{4,}\d{2}$/;
  return termRegex.test(trimmedTerm);
};

const isValidCourseId = (courseId) => {
  const trimmedCourseId = courseId.trim();
  const courseRegex = /^[A-Z]+\d+$/;
  return courseRegex.test(trimmedCourseId);
};

const validateClasses = (classes, classType) => {
  for (const cls of classes) {
    if (cls.class_name || cls.term) {
      if (cls.class_name && !isValidCourseId(cls.class_name.trim())) {
        throw new Error(`Invalid ${classType} course ID format. Use capital letters followed by numbers with no spaces (e.g., ECON462)`);
      }
      if (cls.term && !isValidTerm(cls.term.trim())) {
        throw new Error(`Invalid ${classType} term format. Please type the full term name in capital letters followed by two numbers, with no spaces (e.g., FALL23, WINTER23)`);
      }
    }
  }
};


const SignupPage = () => {
  const isMounted = useRef(true);
  const abortController = useRef(new AbortController());

  const [signupFields, setSignupFields] = useState({
    name: "",
    email: "",
    password: "",
    university: "",
    profile_picture: null,
    pronouns: "",
    bio: "",
    taken_classes: [{ class_name: "", term: "" }],
    planned_classes: [{ class_name: "", term: "" }],
    current_classes: [{ class_name: "", term: "" }],
  });
  const [universities, setUniversities] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [formData, setFormData] = useState(null);
  
  // State for university search input and filtered suggestions
  const [universitySearch, setUniversitySearch] = useState("");
  const [filteredUniversities, setFilteredUniversities] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const navigate = useNavigate();

  const resetState = () => {
    setUniversities([]);
    setError("");
    setIsLoading(false);
    setSignupSuccess(false);
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      abortController.current.abort();
      resetState();
    };
  }, []);

  useEffect(() => {
    const fetchUniversities = async () => {
      abortController.current = new AbortController();

      try {
        if (!isMounted.current) return;
        const response = await axios.get("/api/schools", {
          headers: { "Content-Type": "application/json" },
          signal: abortController.current.signal,
        });

        if (!isMounted.current) return;
        setUniversities(response.data.schools || []);
        setError("");
      } catch (error) {
        if (!isMounted.current) return;
        if (axios.isCancel(error)) {
          console.log('Request cancelled');
          return;
        }
        console.error("Failed to load universities:", error);
        setError("Failed to load universities. Please try again later.");
      }
    };

    fetchUniversities();

    return () => {
      abortController.current.abort();
    };
  }, []);

  // Filter universities as the user types
  useEffect(() => {
    if (!isMounted.current) return;
    if (!universitySearch) {
      setFilteredUniversities([]);
      return;
    }
    const searchVal = universitySearch.toLowerCase();
    const results = universities
      .filter(u => u.toLowerCase().includes(searchVal))
      .slice(0, 10); // limit suggestions to top 10
    setFilteredUniversities(results);
  }, [universitySearch, universities]);

  const handleSignup = (e) => {
    e.preventDefault();
    if (!isMounted.current) return;
    if (!signupFields.email.toLowerCase().endsWith('.edu')) {
      setError('Please use a valid .edu email address');
      return;
    }
  
    // Create trimmed versions of the classes for validation
    const trimmedClasses = {
      taken_classes: signupFields.taken_classes.map(cls => ({
        class_name: cls.class_name.trim(),
        term: cls.term.trim()
      })),
      current_classes: signupFields.current_classes.map(cls => ({
        class_name: cls.class_name.trim(),
        term: cls.term.trim()
      })),
      planned_classes: signupFields.planned_classes.map(cls => ({
        class_name: cls.class_name.trim(),
        term: cls.term.trim()
      }))
    };
  
    try {
      validateClasses(trimmedClasses.taken_classes, 'previous');
      validateClasses(trimmedClasses.current_classes, 'current');
      validateClasses(trimmedClasses.planned_classes, 'planned');
    } catch (validationError) {
      setError(validationError.message);
      return;
    }
  
    const formData = new FormData();
    
    // Handle non-class fields first
    formData.append('name', signupFields.name.trim());
    formData.append('email', signupFields.email.trim());
    formData.append('password', signupFields.password);
    formData.append('university', signupFields.university.trim());
    formData.append('pronouns', signupFields.pronouns.trim());
    formData.append('bio', signupFields.bio.trim());
    
    if (signupFields.profile_picture) {
      formData.append('profile_picture', signupFields.profile_picture);
    }
  
    // Handle class arrays with proper trimming only during submission
    ['taken_classes', 'current_classes', 'planned_classes'].forEach(key => {
      const classes = signupFields[key]
        .filter(cls => cls.class_name.trim() !== "" || cls.term.trim() !== "")
        .map(cls => [cls.class_name.trim(), cls.term.trim()]);
      
      formData.append(key, JSON.stringify(classes));
    });
  
    setFormData(formData);
    setShowTermsModal(true);
  };

   // Add real-time validation to the updateClassField function
   const updateClassField = (classArrayName, index, field, value) => {
    if (!isMounted.current) return;
    
    // Clear any errors when user starts typing
    setError("");
    
    // Remove spaces as the user types
    const noSpaces = value.replace(/\s+/g, '');
    
    // Update the field with spaces removed
    setSignupFields((prev) => ({
      ...prev,
      [classArrayName]: prev[classArrayName].map((item, idx) =>
        idx === index ? { ...item, [field]: noSpaces } : item
      ),
    }));
  };

  const handleTermsAccept = async () => {
    if (!agreedToTerms) {
      setError('You must agree to the terms and privacy policy to continue');
      return;
    }
    
    setIsLoading(true);
    const submitController = new AbortController();
  
    try {
      const response = await axios.post("/api/signup", formData, {
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" },
        signal: submitController.signal,
      });
  
      if (!isMounted.current) return;
      setSignupSuccess(true);
      navigate("/verification-sent");
    } catch (error) {
      if (!isMounted.current || axios.isCancel(error)) return;
  
      if (error.response?.data?.detail?.includes('already exists')) {
        setError('An account already exists with this email.');
      } else {
        console.error('Detailed error:', error);
        console.error('Response data:', error.response?.data);
        setError(`Signup error: ${error.response?.data?.detail || error.message}`);
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
        setShowTermsModal(false);
      }
    }
  };

  const updateField = (fieldName, value) => {
    if (!isMounted.current) return;
    // Clear any errors when user starts typing
    setError("");
    setSignupFields((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleInputFocus = () => {
    if (!isMounted.current) return;
    setError("");
  };


  const addClassRow = (classArrayName) => {
    if (!isMounted.current) return;
    setSignupFields((prev) => ({
      ...prev,
      [classArrayName]: [...prev[classArrayName], { class_name: "", term: "" }],
    }));
  };

  // When user picks a university from suggestions
  const handleUniversitySelect = (uni) => {
    updateField("university", uni);
    setUniversitySearch(uni);
    setFilteredUniversities([]);
  };

  return (
    <div className="min-h-screen bg-[#2B5534] flex items-center justify-center p-4">
      <div className="bg-white rounded-3xl w-full max-w-6xl p-8">
        <div className="text-center mb-8">
          <h2 className="text-[#2B5534] text-2xl font-semibold mb-2">Join CourseLinx</h2>
          <p className="text-gray-600 text-sm">Start your academic journey</p>
          <p className="text-gray-600 text-sm">Fields marked with <span className="text-red-600 font-semibold">*</span> are required</p>
        </div>

        {signupSuccess && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded mb-4">
            <span className="block sm:inline">
              A verification email has been sent to your email address. Please check your inbox and follow the instructions to complete your registration.
            </span>
          </div>
        )}

        <form onSubmit={handleSignup} className="space-y-4">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm mb-1">First and Last Name <span className="text-red-600">*</span></label>
              <input
                type="text"
                required
                className="w-full px-3 py-2 rounded-lg bg-[#F5F7FB] border-none focus:ring-2 focus:ring-green-500 text-gray-900"
                value={signupFields.name}
                onChange={(e) => updateField("name", e.target.value)
                }
                onFocus={handleInputFocus}
              />
            </div>

            <div>
              <label className="block text-sm mb-1">University Email <span className="text-red-600">*</span></label>
              <input
                type="email"
                required
                className="w-full px-3 py-2 rounded-lg bg-[#F5F7FB] border-none focus:ring-2 focus:ring-green-500 text-gray-900"
                value={signupFields.email}
                onChange={(e) => updateField("email", e.target.value)}
                onFocus={handleInputFocus}
              />
            </div>

            <div>
              <label className="block text-sm mb-1">Password <span className="text-red-600">*</span></label>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  required
                  className="w-full px-3 py-2 rounded-lg bg-[#F5F7FB] border-none focus:ring-2 focus:ring-green-500 text-gray-900"
                  value={signupFields.password}
                  onChange={(e) => updateField("password", e.target.value)}
                  onFocus={handleInputFocus}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeOff className="h-4 w-4 text-gray-400" /> : <Eye className="h-4 w-4 text-gray-400" />}
                </button>
              </div>
            </div>

            <div className="relative">
              <label className="block text-sm mb-1">University <span className="text-red-600">*</span></label>
              <input
                type="text"
                required
                placeholder="Type to search..."
                className="w-full px-3 py-2 rounded-lg bg-[#F5F7FB] border-none focus:ring-2 focus:ring-green-500 text-gray-900"
                value={universitySearch}
                onChange={(e) => {
                  setUniversitySearch(e.target.value);
                  updateField("university", "");
                  setShowSuggestions(true);
                }}
                onFocus={handleInputFocus}
              />
              {showSuggestions && filteredUniversities.length > 0 && (
              <ul className="absolute z-10 bg-white border border-gray-200 rounded-lg mt-1 w-full max-h-48 overflow-auto shadow-lg">
                {filteredUniversities.map((uni, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      handleUniversitySelect(uni);
                      setShowSuggestions(false);
                    }}
                    className="px-3 py-2 cursor-pointer hover:bg-green-100"
                  >
                    {uni}
                  </li>
                ))}
              </ul>
            )}
            </div>

            <div>
              <label className="block text-sm mb-1">Pronouns <span className="text-red-600">*</span></label>
              <input
                type="text"
                required
                className="w-full px-3 py-2 rounded-lg bg-[#F5F7FB] border-none focus:ring-2 focus:ring-green-500 text-gray-900"
                value={signupFields.pronouns}
                onChange={(e) => updateField("pronouns", e.target.value)}
                onFocus={handleInputFocus}
              />
            </div>
            <div>
              <label className="block text-sm mb-1">Profile Picture (Optional)</label>
              <input
                type="file"
                accept="image/*"
                className="w-full px-3 py-2 rounded-lg bg-[#F5F7FB] border-none focus:ring-2 focus:ring-green-500 text-gray-900"
                onChange={(e) => updateField("profile_picture", e.target.files[0])}
                onFocus={handleInputFocus}
              />
            </div>
            <div className="sm:col-span-3">
              <label className="block text-sm mb-1">Bio <span className="text-red-600">*</span></label>
              <textarea
                required
                rows={3}
                className="w-full px-3 py-2 rounded-lg bg-[#F5F7FB] border-none focus:ring-2 focus:ring-green-500 text-gray-900"
                value={signupFields.bio}
                onChange={(e) => updateField("bio", e.target.value)}
              ></textarea>
            </div>
          </div>

          {["taken_classes", "current_classes", "planned_classes"].map((classType, idx) => (
            <div key={idx} className="mt-4">
              <h3 className="text-lg font-medium mb-2">
                {classType === "taken_classes"
                  ? "Previous Courses"
                  : classType === "current_classes"
                  ? "Current Courses"
                  : "Planned Courses"}
              </h3>
              <h3 className="text-sm text-gray-600 mb-2">
                {classType === "taken_classes"
                  ? "Please put previous classes where you would be comfortable answering some quick questions from another student."
                  : classType === "current_classes"
                  ? "Add the classes you are currently taking. This helps you connect with classmates."
                  : "Mention the classes you plan to take. This helps you find advice and guidance for future courses."}
              </h3>
              {signupFields[classType].map((cls, index) => (
              <div key={index} className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-3">
                <div>
                  <label className="block text-sm mb-1">Course ID</label>
                  {index === 0 && (
                    <p className="text-xs text-gray-600 mb-1">Capitalize all letters of the subject as it appears on your transcript, and include the course number with no spaces. Ex: 'ECON 462' is 'ECON462'.</p>
                  )}
                  <input
                    type="text"
                    className="w-full px-3 py-2 rounded-lg bg-[#F5F7FB]"
                    value={cls.class_name}
                    onChange={(e) => updateClassField(classType, index, "class_name", e.target.value)}
                    onFocus={handleInputFocus}
                  />
                </div>
                <div>
                  <label className="block text-sm mb-1">Term (required)</label>
                  {index === 0 && (
                    <p className="text-xs text-gray-600 mb-1">Capitalize all letters of the term name, and join it with the last two numbers of the year without spaces. Ex: 'Fall 2023' is 'FALL23'.</p>
                  )}
                  <input
                    type="text"
                    className="w-full px-3 py-2 rounded-lg bg-[#F5F7FB]"
                    value={cls.term}
                    onChange={(e) => updateClassField(classType, index, "term", e.target.value)}
                    onFocus={handleInputFocus}
                  />
                </div>
              </div>
            ))}
              <button
                type="button"
                onClick={() => addClassRow(classType)}
                className="px-3 py-1.5 bg-[#4CAF50] text-white rounded-lg hover:bg-[#45a049] transition-colors"
              >
                Add Class
              </button>
            </div>
          ))}
          {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded mb-4">
            <span className="block sm:inline">{error}</span>
          </div>
        )}
          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-[#4CAF50] text-white py-2 rounded-lg hover:bg-[#45a049] transition-colors mt-4"
          >
            {isLoading ? 'Signing up...' : 'Sign Up'}
          </button>
          
          <div className="text-center mt-4">
            <p className="text-sm text-gray-600">
              Already have an account?{" "}
              <Link to="/login" className="text-[#4CAF50] hover:underline">
                Login
              </Link>
            </p>
          </div>
          <div className="text-center">
            <p className="text-sm text-gray-600">
              Want to go back?{" "}
              <Link to="/" className="text-[#4CAF50] hover:underline">
                Here
              </Link>
            </p>
          </div>
        </form>
      </div>
      {showTermsModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white rounded-lg p-8 max-w-2xl w-full mx-4 max-h-[80vh] overflow-y-auto">
      <h2 className="text-2xl font-semibold mb-4">Terms of Service, Privacy Policy, and Trademark Usage</h2>
      
      <div className="mb-6">
        <h3 className="text-lg font-medium mb-2">Terms of Service</h3>
        <p className="mb-4 text-xs leading-relaxed">
        Effective Date: December 15th, 2024.
        Welcome to CourseLinx! By accessing or using our platform, you agree to these Terms of Service. If you do not agree, please do not use CourseLinx.
        By creating an account or using CourseLinx, you agree to comply with these Terms of Service (“ToS”). You must be 13 years or older to use this platform.
        CourseLinx provides an educational social media platform where students can connect, share course experiences, and communicate via school-provided email addresses.
        You must provide accurate information during signup, including your name, university email, and classes.
        You are responsible for maintaining the confidentiality of your account credentials.
        Accounts may be terminated if false information is provided or ToS is violated.
        By using CourseLinx, you agree NOT to: share false information or impersonate others, harass, spam, or misuse other users' emails, violate any local, state, or international laws, and post offensive, innappropriate, or illegal content.
        You retain ownership of the content you submit, such as course data. CourseLinx has the right to remove content that violates these terms.
        You agree to receive communications, such as emails related to your account or services, from CourseLinx.
        CourseLinx may terminate or suspend your account for violation of these terms without notice.
        CourseLinx is provided “as is.” We do not guarantee uninterrupted or error-free service.
        CourseLinx is not liable for any indirect, incidental, or consequential damages resulting from your use of the platform.
        We may update these Terms of Service at any time. Continued use of the platform constitutes acceptance of the updated terms.
        If you have any questions, contact us at: info@insilicodigital.com.
        </p>

        <h3 className="text-lg font-medium mb-2">Privacy Policy</h3>
        <p className="mb-4 text-xs leading-relaxed">
          Effective Date: December 15th, 2024.
          CourseLinx is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and protect your information.
          We collect the following types of information: name, university email, profile data (picture), classes you have taken, are currently taking, or plan to take.
          We use your information to provide and improve CourseLinx's services, facilitate communication between students, send important updates about your account, and prevent misuse of the platform.
          We do NOT sell or share your personal information, except: with third party service providers (email APIs) necessary for the platform to function, or if required by law or to protect CourseLinx's legal rights.
          We use cookies and similar technologies to enhance your user experience.
          We retain your data as long as your account is active. You may delete your account from the 'My Profile' page.
          Depending on your location, you have rights to access your data, request corrections, and request account deletion.
          CourseLinx is not intended for children under 13 years old. If we learn a child's data was collected, we will delete it immediately.
          We implement reasonable security measures to protect your data but cannot guarantee 100% security.
          We may update this policy. We will notify you of significant changes via email or on the platform.
          If you have questions about this Privacy Policy, contact us at: info@insilicodigital.com.
        </p>

        <h3 className="text-lg font-medium mb-2">Trademark Usage and Ownership</h3>
        <p className="mb-4 text-xs leading-relaxed">
          Effective Date: December 15th, 2024.
          The name CourseLinx™ along with its logo, slogans, and any related product or service names, are trademarks or service marks of INSILICO Digital LLC. All rights to these marks are expressly reserved.
          You may not use, reproduce, display, distribute, or otherwise exploit the CourseLinx™ name, logo, or any trademarks, whether in whole or in part, in any form of media, advertising, marketing, domain names, or promotional material without prior written consent from INSILICO Digital LLC. 
          Unauthorized use of the CourseLinx™ trademark or any simialr marks that may cause confusion is strictly prohibited and may result in legal action. If you wish to request permission for the use of CourseLinx™ trademarks, contact us at info@insilicodigital.com. 
        </p>
      </div>

      <div className="flex items-center mb-6">
        <input
          type="checkbox"
          id="terms-agree"
          checked={agreedToTerms}
          onChange={(e) => setAgreedToTerms(e.target.checked)}
          className="mr-2"
        />
        <label htmlFor="terms-agree" className="text-sm text-gray-700">
          I have read and agree to the Terms of Service and Privacy Policy
        </label>
      </div>

      <div className="flex justify-end space-x-4">
        <button
          onClick={() => setShowTermsModal(false)}
          className="px-4 py-2 text-gray-600 hover:text-gray-800"
        >
          Cancel
        </button>
        <button
          onClick={handleTermsAccept}
          disabled={!agreedToTerms || isLoading}
          className={`px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ${
            (!agreedToTerms || isLoading) ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isLoading ? 'Processing...' : 'Accept & Continue'}
        </button>
      </div>
    </div>
  </div>
)}
    </div>
  );
};

export default SignupPage;