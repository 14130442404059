import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ isAuthenticated, children }) => {
  if (isAuthenticated === null) {
    // Still checking auth; show a spinner or skeleton
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-800"></div>
      </div>
    );
  }

  if (isAuthenticated === false) {
    // Not authenticated
    return <Navigate to="/login" replace />;
  }

  // Authenticated => render the child component
  return children;
};

export default PrivateRoute;