import React, { useState } from 'react';
import { Eye, EyeOff, LogIn } from 'lucide-react';
import axios from "axios";

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState('');  // Add this state for error messages

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');  // Clear any previous errors
  
    try {
      await axios.post('/api/login', { email, password }, {withCredentials: true });
      // If we get here, login was successful
      window.location.href = '/students';
    } catch (err) {
      console.error(err);
      // Get the error message from the backend if available
      setError("Login failed.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    
    <div className="min-h-screen bg-[#2B5534] flex items-center justify-center">
      <div className="bg-white rounded-3xl w-full max-w-md mx-4 p-8">
        <div className="text-center mb-12">
        {error && (
  <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded mb-4">
    {error}
  </div>
)}
          <h1 className="text-[#2B5534] text-2xl font-semibold mb-2">Welcome Back</h1>
          <p className="text-gray-600 text-sm">Continue your academic journey</p>
        </div>

        <form onSubmit={handleLogin} className="space-y-6">
          <div className="space-y-6">
            <div>
              <label className="block text-sm mb-2">
                University Email
              </label>
              <input
                type="email"
                required
                className="w-full px-4 py-3 rounded-lg bg-[#F5F7FB] border-none focus:ring-2 focus:ring-green-500"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div>
              <label className="block text-sm mb-2">
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  required
                  className="w-full px-4 py-3 rounded-lg bg-[#F5F7FB] border-none focus:ring-2 focus:ring-green-500"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeOff className="h-5 w-5 text-gray-400" />
                  ) : (
                    <Eye className="h-5 w-5 text-gray-400" />
                  )}
                </button>
              </div>
            </div>

          </div>

          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-[#4CAF50] text-white py-3 rounded-lg hover:bg-[#45a049] transition-colors"
          >
            {isLoading ? 'Signing in...' : 'Sign In'}
          </button>

          <div className="flex justify-between text-sm text-[#4CAF50]">
            <a href="/forgot-password" className="hover:underline">
              Forgot your password?
            </a>
            <a href="/signup" className="hover:underline">
              Create an account
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;